import { createRouter, createWebHashHistory } from 'vue-router'

import AntLayout from '@/views/ant/AntLayout.vue'
import ErrorPage from '@/views/ant/ErrorPage.vue'
import MaterialIcon from '@/views/demo/MaterialIcon.vue'
import NotFound from '@/views/errors/NotFound.vue'

const routes = [
    {
        path: "/conference/:vol",
        name: "AntConferenceLayout",
        component: AntLayout,
        meta: {
            title: "Wideokonferencja MWC"
            },
    },
    {
        path: "/material-demo",
        name: "material-demo",
        component: MaterialIcon,
        meta: {
            title: "Demo MWC"
        },
    },
    {
        path: "/error/:code",
        name: "error",
        component: ErrorPage,
        meta: {
            title: "Błąd "
        },
    },
    {
        path: "/404",
        name: "NotFound",
        component: NotFound,
        meta: {
            title: "404 - Nie znaleziono"
        }
    },
    {
        path: '/:pathMatch(.*)',
        component: NotFound
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to) => {
    document.title = "Ant App"


    //let redirect;

    if (to.meta.title)
        document.title = to.meta.title

    //if (to.meta.requiresAuth) {
    //    if (store.state.auth.status.loggedIn) {
    //        if (to.path == '/') {
    //            redirect = '/Dashboard'
    //        }
    //    }
    //    else {
    //        redirect = '/auth/login'
    //    }

    //}
    //else {
    //    if (to.name == "AuthLogin") {
    //        if (store.state.auth.status.loggedIn) {
    //            redirect = '/Dashboard'
    //        }

    //    }
    //}

    //next(redirect)

});

export default router;
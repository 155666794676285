class GlobalParser {
    ParserApiDListChanged(source) {
        let response = {
            action: "",
            uid: -1,
            state: -1,
        };

        if (source == null)
            return response;

        let result = source.split("|");

        if (result.length != 3)
            return response;

        response.action = result[0];
        response.uid = parseInt(result[1]);
        response.state = parseInt(result[2]);
        return response;
    }

    ExtractUserIdFromStreamId(streamId) {
        let streamElementArray = streamId.split('-');
        return parseInt(streamElementArray.pop());
    }

    GetUserAvatarPath(userId) {
        return "https://ssl.esesja.pl/users/" + userId + ".jpg";
    }
}

export default new GlobalParser();
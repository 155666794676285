import ApiService from "./api.service";
import { encryptStorage } from '@/services/encrypt.storage.service.js';

class AntService extends ApiService {
    getAuthorization(data) {
        return this.post(`ant/authorization`, data, this.GetConfig())
    }

    checkPinAuth(pin) {
        return this.post(`ant/checkPinData`, pin, this.GetConfig())
    }

    DeleteToken(data) {
        return this.post(`ant/deleteToken`, data, this.GetConfig()).then(() => {
            encryptStorage.removeItem('ant_tk');
        });
    }

    getCurrentParticipantList(rid, type=0) {
        return this.get(`ant/CurrentParticipantList/${rid}/${type}`, this.GetConfig())
    }

    getCurrentViewersCount(rid) {
        return this.get(`ant/GetCurrentViewersCount/${rid}`, this.GetConfig())
    }

    saveFrontError(error) {
        return this.post(`ant/saveFrontError`, error, this.GetConfig())
    }

    GetConfig() {
        return {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${encryptStorage.getItem("ant_tk")}`
            }
        }
    }
}

export default new AntService();
<template>
    <footer id="player-footer-button" class="footer mt-auto py-3">
        <div class="player-fotter-button-overlay">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-6 col-sm-4 col-md-4">
                        <div class="helper-conference-button">
                            <button v-if="internalModuleState.liveStream && internalModuleState.isScreenShareSupported" type="button" class="btn btn-dark btn-circle btn-space" id="shareScreenOptions" data-bs-toggle="dropdown" aria-expanded="false"><svg-icon type="mdi" :path="icons.monitorShare" :size="iconSize.currentSize"></svg-icon></button>

                            <ul class="dropdown-menu" aria-labelledby="shareScreenOptions">
                                <li><a class="dropdown-item" @click="changeVideoSource('camera')">Obraz z kamery</a></li>
                                <li><a class="dropdown-item" @click="changeVideoSource('screen')">Współdzielenie ekranu</a></li>
                                <li><a class="dropdown-item" @click="changeVideoSource('camera+screen')">Wspóldzielenie ekranu z kamerą</a></li>
                            </ul>

                            <button v-if="internalModuleState.hand && !internalModuleState.isPublisher" type="button" class="btn btn-dark btn-circle btn-space btn-voice-request" @click="voiceResignation"><svg-icon type="mdi" :path="icons.hand" :size="iconSize.currentSize"></svg-icon></button>
                            <button v-if="!internalModuleState.hand && !internalModuleState.isPublisher" type="button" class="btn btn-dark btn-circle btn-space" @click="voiceRequest"><svg-icon type="mdi" :path="icons.hand" :size="iconSize.currentSize"></svg-icon></button>


                            <button type="button" class="btn btn-dark btn-circle btn-space position-relative" @click="toogleParicipantList">
                                <svg-icon type="mdi" :path="icons.people" :size="iconSize.currentSize"></svg-icon>
                                <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light text-dark ">
                                    {{internalPeopleCounter.participants}}
                                    <span class="visually-hidden">Uczestników spotkania</span>
                                </span>
                            </button>
                        </div>
                    </div>
                    <div class="col-6 col-sm-4 col-md-4 d-flex justify-content-start justify-content-sm-center">
                        <div class="main-conference-button" v-if="internalModuleState.liveStream">

                            <button v-if="internalModuleState.microphoneState" type="button" class="btn btn-light btn-circle btn-space" @click="internalTurnMicrophoneOff"><svg-icon type="mdi" :path="icons.microphoneOn" :size="iconSize.currentSize"></svg-icon></button>
                            <button v-else type="button" class="btn btn-secondary btn-circle btn-space" @click="internalTurnMicrophoneOn"><svg-icon type="mdi" :path="icons.microphoneOff" :size="iconSize.currentSize"></svg-icon></button>

                            <button type="button" class="btn btn-danger btn-circle btn-space" @click="endCall">
                                <svg-icon type="mdi" :path="icons.endCall" :size="iconSize.currentSize"></svg-icon>
                            </button>

                            <button v-if="internalModuleState.cameraState" type="button" class="btn btn-light btn-circle btn-space" @click="internalTurnCameraOff"><svg-icon type="mdi" :path="icons.cameraOn" :size="iconSize.currentSize"></svg-icon></button>
                            <button v-else type="button" class="btn btn-secondary btn-circle btn-space" @click="internalTurnCameraOn"><svg-icon type="mdi" :path="icons.cameraOff" :size="iconSize.currentSize"></svg-icon></button>
                        </div>
                    </div>
                    <!--<div class="col-4 d-flex justify-content-end" >
                <div class="extend-conference-button">
                    <button type="button" class="btn btn-dark btn-circle btn-space" id="moreMenu" data-bs-toggle="dropdown" aria-expanded="false"><svg-icon type="mdi" :path="icons.more" :size="30"></svg-icon></button>

                    <ul class="dropdown-menu" aria-labelledby="moreMenu">
                        <li><a class="dropdown-item" @click="toggleStatictics()">Statystyki</a></li>
                    </ul>
                </div>
            </div>-->
                </div>
            </div>
        </div>
    </footer>
</template>
<script charset="utf-8">
    import SvgIcon from '@jamescoyle/vue-icon';
    import { mdiHandBackRight, mdiMonitorShare, mdiAccountGroup, mdiMicrophone, mdiMicrophoneOff, mdiCamera, mdiCameraOff, mdiPhoneHangup, mdiDotsVertical } from '@mdi/js';
    export default {
        components: {
            SvgIcon
        },
        props: {
            moduleState: {
                type: Object,
                required: true,
            },

            peopleCounter: {
                type: Object,
                required: true,
            }
        },
        emits: [
            "turnMicrophoneOff",
            "turnMicrophoneOn",
            "endCall",
            "turnCameraOff",
            "turnCamearaOn",
            "toogleParicipantList",
            "changeVideoSource",
            "voiceRequest",
            "voiceResignation",
            "toggleStatictics"],
        data() {
            return {
                publicPath: process.env.BASE_URL,
                icons: {
                    hand: mdiHandBackRight,
                    monitorShare: mdiMonitorShare,
                    people: mdiAccountGroup,
                    microphoneOn: mdiMicrophone,
                    microphoneOff: mdiMicrophoneOff,
                    cameraOn: mdiCamera,
                    cameraOff: mdiCameraOff,
                    endCall: mdiPhoneHangup,
                    more: mdiDotsVertical,
                },

                internalModuleState: {
                    cameraState: 0, //0 - off, 1 - on
                    microphoneState: 0, //0 - off, 1 - on
                    hand: 0, //0 - down, 1 - up,
                    liveStream: false,
                    isPublisher: false,
                    isScreenShareSupported: false
                },

                iconSize: {
                    maxSize: 30,
                    minSize: 15,
                    currentSize: 30,
                },

                resolutionConfig: {
                    maxResolution: {
                        width: 1920,
                        height: 1080
                    },
                    minResolution: {
                        width: 320,
                        height: 240
                    },
                    currentResolution: {
                        width: 1920,
                        height: 1080
                    }
                },
                internalPeopleCounter: {
                    participants: 0,
                    viewers: 0,
                },
            };
        },

        watch: {
            moduleState: {
                immediate: true,
                handler(val) {
                    this.internalModuleState = val;
                }
            },
            peopleCounter: {
                immediate: true,
                handler(val) {
                    this.internalPeopleCounter = val;
                }
            },
        },
        computed: {

        },
        mounted() {
            this.calculateIconSize(window.innerWidth, window.innerHeight);
            window.addEventListener("resize", this.catchChangeWindowsSize);
        },
        unmounted() {
            window.removeEventListener("resize", this.catchChangeWindowsSize);
        },
        methods: {
            catchChangeWindowsSize(e) {
                this.calculateIconSize(e.currentTarget.innerWidth, e.currentTarget.innerHeight);
            },

            calculateIconSize(width, height) {
                this.resolutionConfig.currentResolution.width = width;
                this.resolutionConfig.currentResolution.height = height;

                if (this.resolutionConfig.currentResolution.width > this.resolutionConfig.maxResolution.width)
                    this.resolutionConfig.currentResolution.width = this.resolutionConfig.maxResolution.width;

                if (this.resolutionConfig.currentResolution.width < this.resolutionConfig.minResolution.width)
                    this.resolutionConfig.currentResolution.width = this.resolutionConfig.minResolution.width;

                let a = 0.009375
                let b = 12

                this.iconSize.currentSize = a * this.resolutionConfig.currentResolution.width + b;
            },

            internalTurnMicrophoneOff() {
                this.$emit("turnMicrophoneOff");
            },
            internalTurnMicrophoneOn() {
                this.$emit("turnMicrophoneOn"); 
            },

            internalTurnCameraOff() {
                this.$emit("turnCameraOff");
            },

            internalTurnCameraOn() {
                this.$emit("turnCamearaOn"); 
            },

            changeVideoSource(source) {
                this.$emit("changeVideoSource", source);
            },
            toogleParicipantList() {
                this.$emit("toogleParicipantList");
            },
            endCall() {
                this.$emit("endCall");
            },

            voiceResignation() {
                this.$emit("voiceResignation");
            },
            voiceRequest() {
                this.$emit("voiceRequest");
            },
            toggleStatictics() {
                this.$emit("toggleStatictics");
            }

        }
    }
</script>
<style>
    .footer{
        position:fixed;
        bottom:0;
        left:0;
        right:0;
        background: transparent;
    }

    #player-footer-button {
        /*background: rgba(0,0,0,0.5);*/
        background-image: linear-gradient(to top, rgba(0,0,0,0.9), rgba(0,0,0,0));
        z-index: 999;
    }
    .player-fotter-button-overlay {
        opacity: 0.5;
    }
    .player-fotter-button-overlay:hover {
        opacity: 1.0;
    }




    #player-footer-button .btn-voice-request {
        color: #00ff00 !important;
    }

    .btn-space {
        margin: 10px 1vw;
    }


    #player-footer-button .dropdown-item {
        padding: 5px 10px;
        text-align: center;
    }

    .btn-circle {
        width: 40px;
        height: 40px;
        padding: 6px 0px;
        border-radius: 20px;
        text-align: center;
        line-height: 1.42857;
    }

    @media (min-width: 768px) {
        .btn-circle {
            width: 50px;
            height: 50px;
            padding: 6px 0px;
            border-radius: 25px;
            text-align: center;
            line-height: 1.42857;
        }

        #player-footer-button .dropdown-item {
            padding: 10px 20px;
            text-align: center;
        }
    }

    @media (min-width: 1200px) {
        .btn-circle {
            width: 60px;
            height: 60px;
            padding: 6px 0px;
            border-radius: 30px;
            text-align: center;
            line-height: 1.42857;
        }

        #player-footer-button .dropdown-item {
            padding: 10px 20px;
            text-align: center;
        }
    }

   
</style>
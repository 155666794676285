<template>
    <svg-icon type="mdi" :path="icons.hand" :size="48"></svg-icon>
    <svg-icon type="mdi" :path="icons.monitorShare"></svg-icon>
    <svg-icon type="mdi" :path="icons.people"></svg-icon>
    <svg-icon type="mdi" :path="icons.microphoneOn"></svg-icon>
    <svg-icon type="mdi" :path="icons.microphoneOff"></svg-icon>
    <svg-icon type="mdi" :path="icons.cameraOn"></svg-icon>
    <svg-icon type="mdi" :path="icons.cameraOff"></svg-icon>
    <svg-icon type="mdi" :path="icons.endCall"></svg-icon>
    <AntConferenceLogo />
</template>
<script charset="utf-8">
    import AntConferenceLogo from "@/components/ant/AntConferenceLogo.vue";
    import SvgIcon from '@jamescoyle/vue-icon';
    import { mdiHandBackRight, mdiMonitorShare, mdiAccountGroup, mdiMicrophone, mdiMicrophoneOff, mdiCamera, mdiCameraOff, mdiPhoneHangup } from '@mdi/js';
    export default {
        components: {
            SvgIcon,
            AntConferenceLogo
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                icons: {
                    hand: mdiHandBackRight,
                    monitorShare: mdiMonitorShare,
                    people: mdiAccountGroup,
                    microphoneOn: mdiMicrophone,
                    microphoneOff: mdiMicrophoneOff,
                    cameraOn: mdiCamera,
                    cameraOff: mdiCameraOff,
                    endCall: mdiPhoneHangup,
                },
            };
        },
        computed: {

        },
        methods: {


        }
    }
</script>
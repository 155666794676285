<template>
    <perfect-scrollbar class="mwc-participants-list">
        <ol class="list-group">
            <li v-for="(item, index) in internalParticipantList" :key="index" class="list-group-item list-group-item-action d-flex justify-content-between align-items-middle">
                <!-- User Live State-->
                <div class="d-flex justify-content-between align-items-start">
                    <span class="badge bg-danger rounded-pill" v-if="item.isStream">
                        .
                    </span>
                </div>


                <div class="ms-2 me-2">
                    <div class="fw-normal text-wrap username">{{item.name}}</div>
                </div>
                <div class="d-flex justify-content-between align-items-start">
                    <!-- User Camera State-->
                    <span class="badge bg-primary rounded-pill" v-if="item.cameraState">
                        <svg-icon type="mdi" :path="icons.cameraOn" :size="iconSize.currentSize"></svg-icon>
                    </span>

                    <span class="badge bg-primary rounded-pill" v-else>
                        <svg-icon type="mdi" :path="icons.cameraOff" :size="iconSize.currentSize"></svg-icon>
                    </span>
                    <!-- Microphone State -->

                    <span class="badge bg-primary rounded-pill" v-if="item.microhponeState">
                        <svg-icon type="mdi" :path="icons.microphoneOn" :size="iconSize.currentSize"></svg-icon>
                    </span>

                    <span class="badge bg-primary rounded-pill" v-else>
                        <svg-icon type="mdi" :path="icons.microphoneOff" :size="iconSize.currentSize"></svg-icon>
                    </span>
                </div>

            </li>
        </ol>
    </perfect-scrollbar>
</template>
<script charset="utf-8">
    import SvgIcon from '@jamescoyle/vue-icon';
    import { mdiMonitorShare, mdiMicrophone, mdiMicrophoneOff, mdiCamera, mdiCameraOff} from '@mdi/js';
    import { PerfectScrollbar } from 'vue3-perfect-scrollbar'
    export default {
        components: {
            SvgIcon,
            PerfectScrollbar
        },
        props: {
            participantList: {
                type: Array,
                required: true,
            }
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                icons: {
                    monitorShare: mdiMonitorShare,
                    microphoneOn: mdiMicrophone,
                    microphoneOff: mdiMicrophoneOff,
                    cameraOn: mdiCamera,
                    cameraOff: mdiCameraOff,
                },
                internalParticipantList: [],

                iconSize: {
                    maxSize: 30,
                    minSize: 12,
                    currentSize: 30,
                },

                resolutionConfig: {
                    maxResolution: {
                        width: 1920,
                        height: 1080
                    },
                    minResolution: {
                        width: 320,
                        height: 240
                    },
                    currentResolution: {
                        width: 1920,
                        height: 1080
                    }
                }
            };
        },
        watch: {
            participantList: {
                immediate: true,
                handler(val) {
                    this.internalParticipantList = val;
                }
            },
        },
        computed: {},
        mounted() {
            this.calculateIconSize(window.innerWidth, window.innerHeight);
            window.addEventListener("resize", this.catchChangeWindowsSize);
        },
        unmounted() {
            window.removeEventListener("resize", this.catchChangeWindowsSize);
        },
        methods: {
            catchChangeWindowsSize(e) {
                this.calculateIconSize(e.currentTarget.innerWidth, e.currentTarget.innerHeight);
            },

            calculateIconSize(width, height) {
                this.resolutionConfig.currentResolution.width = width;
                this.resolutionConfig.currentResolution.height = height;

                if (this.resolutionConfig.currentResolution.width > this.resolutionConfig.maxResolution.width)
                    this.resolutionConfig.currentResolution.width = this.resolutionConfig.maxResolution.width;

                if (this.resolutionConfig.currentResolution.width < this.resolutionConfig.minResolution.width)
                    this.resolutionConfig.currentResolution.width = this.resolutionConfig.minResolution.width;

                let a = 0.009375
                let b = 12

                this.iconSize.currentSize = a * this.resolutionConfig.currentResolution.width + b;
            },
        },
    }

</script>
<style type="text/css">
    @import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";

    .mwc-participants-list{
        position: fixed !important;
        top: 10%;
        right:0%;
        overflow-y: scroll;
        height:50%; /* TODO: dopasować*/
        opacity: 0.7;
        width:225px;
    }
    .mwc-participants-list:hover{
        opacity: 1.0;
    }

    .mwc-participants-list .username{
        font-size: 0.8rem;
    }

        .mwc-participants-list .bg-danger {
            color: rgba(var(--bs-danger-rgb),var(--bs-bg-opacity)) !important;
        }
    @media (min-width: 576px) {

        .mwc-participants-list {
            width: 300px;
        }
        .mwc-participants-list .username {
            font-size: 0.9rem;
        }
    }

    @media (min-width: 768px) {

        .mwc-participants-list {
            width: auto;
        }

            .mwc-participants-list .username {
                font-size: 1rem;
            }
    }
</style>
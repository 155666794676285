<template>
    <div class="row mwc-user-info mt-1 mb-2">
        <div class="col-6 offset-3 offset-sm-0 col-sm-12 d-flex justify-content-center">
            <span class="mwc-user-name badge bg-secondary rounded-pill">
                <span class="username h6 text-wrap">{{userName}}</span>
            </span>
        </div>
    </div>
</template>
<script charset="utf-8">
    export default {
        props: {
            userName: {
                type: String,
                required: true,
            }
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
            };
        },
        computed: {},
        methods: {},
    }
</script>
<style type="text/css">
    .mwc-user-info .mwc-user-name {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }

    .mwc-user-info .mwc-user-name .username {
        font-size: 0.8rem;
    }

    @media (min-width: 576px) {

        .mwc-user-info .mwc-user-name {
            padding-top: 0.37rem !important;
            padding-bottom: 0.37rem !important;
            padding-right: 0.75rem !important;
            padding-left: 0.75rem !important;
        }

        .mwc-user-info .mwc-user-name .username {
            font-size: 0.9rem;
        }
    }
    @media (min-width: 768px) {
        .mwc-user-info .mwc-user-name {
            padding-top: 0.5rem !important;
            padding-bottom: 0.5rem !important;
            padding-right: 1rem !important;
            padding-left: 1rem !important;
        }
        .mwc-user-info .mwc-user-name .username {
            font-size: 1rem;
        }
    }

    @media (min-width: 1200px) {
        .mwc-user-info .mwc-user-name {
            padding-top: 0.5rem !important;
            padding-bottom: 0.5rem !important;
            padding-right: 1rem !important;
            padding-left: 1rem !important;
        }
        .mwc-user-info .mwc-user-name .username {
            font-size: 1.0rem;
        }
    }
</style>
<template>
    <router-view />
</template>

<script>
export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
    html, body{
        background:#000;
    }
/*#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}*/
    * {
        transition: opacity 0.15s ease;
    }
    .v-enter-active,
    .v-leave-active {
        transition: opacity 0.15s ease;
    }

    .v-enter-from,
    .v-leave-to {
        opacity: 0;
    }
</style>

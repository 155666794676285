<template>
    <div id="main" class="d-flex flex-column justify-content-center align-items-center">
        <div class="p-2">
            <div class="d-flex flex-row">
                <div class="me-3 pe-3 d-flex flex-column justify-content-center align-items-center border-end">
                    <h1 class="d-flex justify-content-center align-items-center">{{code}}</h1>
                    <h2 class="fw-normal d-flex justify-content-center align-items-center">{{title}}</h2>
                </div>
                <div class="d-flex justify-content-center align-items-center">
                    <h2 class="fw-normal lead" id="desc">{{meessage}}</h2>
                </div>
            </div>
        </div>
        <div class="p-5">
            <a href="https://esesja.pl/" title="eSesja.pl" class="error-esesja-link" target="_blank">
                <img src="https://esesja.pl/img/logo-footer.png" alt="eSesja.pl" />
            </a>
        </div>
    </div>
</template>
<script charset="utf-8">
    export default {
        components: {},
        props: {
            code: {
                type: Number,
                required: true,
            },
            title: {
                type: String,
                required: true,
            },
            meessage: {
                type: String,
                required: true,
            }
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
            }
        },
        mounted() { },
        unmount() { },
        methods: {},
    }

</script>

<style type="text/css">
    #main {
        height: 100vh;
        background: #333;
        color: #eee;
    }
        #main .error-esesja-link{
            opacity: 0.5
        }
        #main .error-esesja-link:hover {
            opacity: 1.0
        }
</style>
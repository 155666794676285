<template>

    <CommonErrorComponent :code="error.code"
                          :title="error.title"
                          :meessage="error.message" />
</template>
<script charset="utf-8">
import CommonErrorComponent from "@/components/common/CommonErrorComponent";
export default {
        components: {
            CommonErrorComponent
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                error: {
                    code: 404,
                    title: "Nie odnaleziono",
                    message: "Strona której szukasz nie została odnaleziona"
                }
            }
        },
        mounted() { },
        unmount() { },
        methods: {},
}

</script>

<template>
    <div class="mwc-video-component">
        <div class="row">
            <div class="mwc-video-item" :class="calculateDivColumnt(availableStreams)" style="scale: 0.85" v-for="(item, index) in availableStreams" :key="index">
                <div class="mwc-video" :class="{'d-none': showVideo(item.streamId) }">

                    <div class="row mwc-video-player">
                        <div class="col-12 d-flex justify-content-center">
                            <div class="ratio ratio-16x9">
                                <video :src-object.prop.camel="item.stream" :id="item.streamId" autoplay playsinline></video>
                            </div>
                        </div>
                        <AntUserLabel :userName="getUserName(item.streamId)" style="scale:1.17" />
                    </div>
                </div>
                <div class="mwc-avatar align-middle" :class="{'d-none': showAvatar(item.streamId)}" style="scale:1.17">
                    <AntUserAvatar :userAvatar="getUserAvatar(item.streamId)" :userName="getUserName(item.streamId)" />
                    <AntUserLabel :userName="getUserName(item.streamId)" />
                </div>
            </div>
        </div>
    </div>
</template>
<script charset="utf-8">
    import AntUserAvatar from "@/components/ant/AntUserAvatar.vue";
    import AntUserLabel from "@/components/ant/AntUserLabel.vue";

    import GlobalParser from '@/library/global.parser.js';
    export default {
        components: {
            AntUserAvatar,
            AntUserLabel,
        },
        props: {
            participantList: {
                type: Array,
                required: true,
            },
            currentUser: {
                type: Object,
                required: true,
            },
            availableStreams: {
                type: Array,
                required: true,
            },
            playOnlyVroom: {
                type: Boolean,
                required: true,
            },
            streamData: {
                type: Object,
                required: true,
            }
        },

        data() {
            return {
                publicPath: process.env.BASE_URL,
                internalParticipantList: [],
                internalAvailableStreams: [],
                internalCurrentUser: {},
                defaultCol: 'col-12',

                componentConfig: {
                    defaultUserName: "Użytkownik eSesji",
                    defaultCameraState: true,
                },
                internalStreamData: {
                    isPublish: false,
                    isFullscreen: false,
                    fullScreenUserId: 0,
                }
            };
        },
        watch: {
            participantList: {
                immediate: true,
                handler(val) {
                    this.internalParticipantList = val;
                }
            },

            currentUser: {
                immediate: true,
                handler(val) {
                    this.internalCurrentUser = val;
                }
            },

            availableStreams: {
                immediate: true,
                handler(val) {
                    this.internalAvailableStreams = val;

                }
            },

            streamData: {
                immediate: true,
                handler(val) {
                    this.internalStreamData = val;

                }
            }
        },
        computed: {

        },
        methods: {

            isShowStream(streamId) {
                if (!this.internalStreamData.isFullscreen) {
                    return true;
                }

                let userId = this.getUserId(streamId)

                if (userId === undefined)
                    return true;

                if (userId === this.internalStreamData.fullScreenUserId)
                    return true;

                return false
            },

            showVideo(streamId) {
                let streamUserId = this.getUserId(streamId);

                if (this.internalStreamData.isFullscreen) {
                    if (this.internalStreamData.fullScreenUserId == streamUserId) {
                        return !this.isUserHasActiveCamera(streamId);
                    }
                    return true;
                }
                return !this.isUserHasActiveCamera(streamId);
            },

            showAvatar(streamId) {
                let streamUserId = this.getUserId(streamId);

                if (this.internalStreamData.isFullscreen) {
                    if (this.internalStreamData.fullScreenUserId == streamUserId) {
                        return this.isUserHasActiveCamera(streamId);
                    }
                    return true;
                }

                return this.isUserHasActiveCamera(streamId);
            },

            calculateDivColumnt(streams) {

                
                if (this.internalStreamData.isFullscreen) {
                    if (streams.find(e => e.streamId.indexOf(this.internalStreamData.fullScreenUserId) !== -1)) {
                        return 'col-12'
                    }
                    else {
                        return 'd-none'
                    }
                }

                let calculatedCol = this.defaultCol;

                let streamsCount = 0;
                if (streams != undefined)
                    streamsCount = streams.length;


                switch (streamsCount) {
                    case 0:
                    case 1:
                        calculatedCol = "col-12";
                        break;
                    case 2:
                        calculatedCol = "col-6";
                        break;
                    case 3:
                        calculatedCol = "col-4";
                        break;
                    default:
                        calculatedCol = this.defaultCol;
                }

                console.log("Calcualted div", calculatedCol);
                return calculatedCol;
            },
            isUserHasActiveCamera(streamId) {
                let userData = this.getUserData(streamId);

                if (userData === null)
                    return this.componentConfig.defaultCameraState;

                if (userData === undefined)
                    return this.componentConfig.defaultCameraState;

                if (Object.keys(userData).length == 0)
                    return this.componentConfig.defaultCameraState;

                return userData.cameraState;

            },

            getUserData(streamId) {
                let userId = this.getUserId(streamId);

                if (userId === undefined)
                    return undefined;

                return this.internalParticipantList.find(el => el.id === userId);
            },

            getUserAvatar(streamId) {
                let userId = this.getUserId(streamId);
                return GlobalParser.GetUserAvatarPath(userId)
            },

            getUserName(streamId) {
                let userData = this.getUserData(streamId);

                if (userData === null)
                    return this.componentConfig.defaultUserName;

                if (userData === undefined)
                    return this.componentConfig.defaultUserName;

                if (Object.keys(userData).length == 0)
                    return this.componentConfig.defaultUserName;

                return userData.name;
            },
            getUserId(streamId) {
                return GlobalParser.ExtractUserIdFromStreamId(streamId);
            }
        }
    }
</script>
<style type="text/css">
</style>
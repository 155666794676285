<template>
    <!--<Transition>
        <AntPeopleCounter :peopleCounter="peopleCounter" v-if="!PlayerOnlySettings.PlayOnlyVroom" />
    </Transition>-->

    <Transition>
        <AntConferenceLogo />
    </Transition>


    <div class="mwc-conference-ant d-flex flex-column min-vw-100">
        <div class="d-flex flex-grow-1 justify-content-center align-items-center">
            <div class="container-fluid">
                <div class="row d-flex align-items-center">
                    <div :class="mwcPublishVideoClassObject" v-if="!PlayerOnlySettings.PlayOnlyVroom">
                        <div class="mwc-video-item" style="scale: 0.85">
                            <div :class="{'d-none': moduleState.cameraState == 0}" class="mwc-video">
                                <div class="row mwc-video-player">
                                    <div class="col-12 d-flex justify-content-center">
                                        <div class="ratio ratio-16x9">
                                            <video ref="mwcConferenceVideo" id="mwc-conference" autoplay muted playsinline></video>
                                        </div>
                                    </div>
                                </div>
                                <AntUserLabel v-if="!PlayerOnlySettings.PlayOnlyVroom" :userName="user.name" style="scale:1.17" />
                            </div>
                            <div v-if="moduleState.cameraState == 0" class="mwc-avatar align-middle" style="scale:1.17">
                                <AntUserAvatar :userAvatar="userAvatar" :userName="user.name" />
                                <AntUserLabel v-if="!PlayerOnlySettings.PlayOnlyVroom" :userName="user.name" />
                            </div>
                        </div>
                    </div>
                    <div :class="mwcClientVideoClassObject">
                        <AntVideoClientComponent :participantList="participantsList"
                                                 :currentUser="user"
                                                 :availableStreams="availableStreams"
                                                 :streamData="streamData"
                                                 :playOnlyVroom="PlayerOnlySettings.PlayOnlyVroom" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <AntConferenceButton v-if="!PlayerOnlySettings.PlayOnlyVroom" 
                         :moduleState="moduleState"
                         :peopleCounter="peopleCounter"
                         @turnMicrophoneOn="turnMicrophoneOn"
                         @turnMicrophoneOff="turnMicrophoneOff"
                         @turnCamearaOn="turnCamearaOn"
                         @turnCameraOff="turnCameraOff"
                         @changeVideoSource="changeVideoSource"
                         @toogleParicipantList="toogleParicipantList"
                         @endCall="endCall"
                         @voiceResignation="voiceResignation"
                         @voiceRequest="voiceRequest"
                         @toggleStatictics="toggleStatictics" />

    <!--<Transition v-if="!PlayerOnlySettings.PlayOnlyVroom">
        <AntVideoStats :videoStatData="videoStatData" v-if="showVideoStatistics" />
    </Transition>-->

    <Transition v-if="!PlayerOnlySettings.PlayOnlyVroom">
        <AntParicipantList :participantList="participantsList" v-if="showParticipantList" />
    </Transition>
</template>
<script charset="utf-8">
    //components
    import AntConferenceButton from "@/components/ant/AntConferenceButton.vue";
    import AntConferenceLogo from "@/components/ant/AntConferenceLogo.vue";
    import AntParicipantList from "@/components/ant/AntParicipantList.vue";
    //import AntPeopleCounter from "@/components/ant/AntPeopleCounter.vue";
    import AntVideoClientComponent from "@/components/ant/AntVideoClientComponent.vue";
    //import AntVideoStats from "@/components/ant/AntVideoStats.vue";
    import AntUserAvatar from "@/components/ant/AntUserAvatar.vue";
    import AntUserLabel from "@/components/ant/AntUserLabel.vue";
        
    //libraries
    import AesElem from '@/library/aes.elem.js';
    import GlobalParser from '@/library/global.parser.js';
    import AntService from '@/services/ant.service.js';
    import { AntStreamManagerService } from '@/services/ant.stream.manager.service';
    import { AntPoputService } from '@/services/ant.poput.service';
    import TableteSesjaService from '@/services/tablet.esesja.service.js';
    import { encryptStorage } from '@/services/encrypt.storage.service.js';

    //external libraries
    import { WebRTCAdaptor } from '@antmedia/webrtc_adaptor';
    import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
    import * as CryptoJS from 'crypto-js';
    import io from 'socket.io-client';

    export default {
        components: {
            AntConferenceButton,
            //AntVideoStats,
            AntParicipantList,
            AntVideoClientComponent,
            AntConferenceLogo,
            //AntPeopleCounter,
            AntUserAvatar,
            AntUserLabel
        },
        data() {
            return {
                userAvatar: null,
                publisherId: 0,
                publicPath: process.env.BASE_URL,
                tempPin: "",
                moduleState: {
                    cameraState: 0, //0 - off, 1 - on
                    microphoneState: 0, //0 - off, 1 - on
                    hand: 0, //0 - down, 1 - up,
                    liveStream: false, // true - streaming, false - viewer
                    isPublisher: false,
                    isScreenShareSupported: false
                },
                webRTCInitalized: false,
                webRTCAdaptor: null,
                rtcConfig: {
                    websocket_url: "wss://ant.mwc.pl:5443/WebRTCAppEE/websocket",

                    peerconnection_config: {
                        'iceServers': [
                            { 'urls': 'stun:stun1.l.google.com:19302' },
                            { 'urls': 'stun:stun.ekiga.net' },
                            //{ 'urls': 'stun:stun.l.google.com:19302' },
                            //{ 'urls': 'stun:stun2.l.google.com:19302' },
                            //{ 'urls': 'stun:stun3.l.google.com:19302' },
                            //{ 'urls': 'stun:stun.schlund.de' },
                        ]
                    },
                    sdp_constraints: {
                        OfferToReceiveAudio: false,
                        OfferToReceiveVideo: false,
                    },
                    mediaConstraints: {
                        video: true,
                        audio: true,
                    },
                    bandwidth: 500,
                    //dataChannelEnabled: true,
                    videoContainer: 'mwc-conference',
                    streamId: '',
                    streamName: '',
                    debug: true,
                },

                esesjaWebSocket: null,

                esesjaWebSocketConfig: {
                    host: 'https://com.esesja.pl',
                },

                signalR: null,
                signalRConfig: {
                    hub: 'mainHub',
                },


                mwcPublishVideoClassObject: {
                    'd-none': false,
                    'col-6': true,
                    'col-12': false,

                },

                mwcClientVideoClassObject: {
                    'col-12': false,
                    'col-6': false,
                },

                antStreamManager: null,
                antPoputService: null,

                showVideoStatistics: false,
                videoStatData: {
                    averageOutgoingBitrate: 0,
                    currentOutgoingBitrate: 0,
                    resWidth: 0,
                    resHeight: 0,
                    frameWidth: 0,
                    frameHeight: 0,
                    videoPacketsLost: 0,
                    audioPacketsLost: 0,
                    videoRoundTripTime: 0,
                    audioRoundTripTime: 0,
                    videoJitter: 0,
                    audioJitter: 0,
                },

                streamData: {
                    isPublish: false,
                    isFullscreen: false,
                    fullScreenUserId: 0,
                    streamsItems: 0,
                },
                
                user: {name: ''},
                participantsList: [],
                showParticipantList: false,
                availableStreams: [],

                externalSystemState: {
                    signalRReady: false,
                    webSocketReady: false,
                    webRTCAdaptorReady: false,
                },

                PlayerOnlySettings: {
                    PlayOnlyVroom: false,
                    Rid: -1,
                },
                peopleCounter: {
                    participants: 0,
                    viewers: 0,
                }
            };
        },
        watch: {
            streamData: {
                immediate: true,
                deep: true,
                handler(val) {
                    if (val.isFullscreen) {
                        if (val.fullScreenUserId !== this.user.id) {
                            this.mwcPublishVideoClassObject['d-none'] = true;
                            this.mwcPublishVideoClassObject['col-6'] = false;

                            this.mwcClientVideoClassObject['col-12'] = true;
                            this.mwcClientVideoClassObject['col-6'] = false;

                            //if (this.this.webRTCInitalized) {
                            //this.turnMicrophoneOff();
                            //}

                        }
                        else {
                            this.mwcPublishVideoClassObject['col-12'] = true;
                            this.mwcPublishVideoClassObject['col-6'] = false;
                        }

                    }
                    else {
                        if (val.isPublish) {
                            //if (this.webRTCInitalized) {
                                this.turnMicrophoneOn(); // TODO - sprawdzić w kontekście powrotu po ubicu czy tutaj nie wraca ustawienie mikrofonu
                            //}
                        }
                        this.mwcPublishVideoClassObject['d-none'] = !val.isPublish;
                        if (val.streamsItems === 0) {
                            this.mwcPublishVideoClassObject['col-6'] = false
                            this.mwcPublishVideoClassObject['col-12'] = true;
                        }
                        else {
                            this.mwcPublishVideoClassObject['col-6'] = true
                            this.mwcPublishVideoClassObject['col-12'] = false;
                        }
                        this.mwcClientVideoClassObject['col-12'] = !val.isPublish;
                        this.mwcClientVideoClassObject['col-6'] = val.isPublish;

                    }

                }
            },

            externalSystemState: {
                immediate: true,
                deep: true,
                handler(val) {
                    console.log("externalSystemState", val);
                    if (this.PlayerOnlySettings.PlayOnlyVroom) {
                        //tylko odtwrzarznie
                        if (val.signalRReady && val.webRTCAdaptorReady) {
                            this.$toast.info("System gotowy", { position: 'top-right'});
                            
                            this.webRTCAdaptor.joinRoom(this.rtcConfig.streamName, this.rtcConfig.streamId);

                            this.signalR.invoke("JoinViewer", this.PlayerOnlySettings.Rid); 
                        }
                    }
                    else {
                        //również publikacja
                        if (val.signalRReady && val.webRTCAdaptorReady && val.webSocketReady) {
                            this.$toast.info("System gotowy", { position: 'top-right' });

                            this.webRTCAdaptor.joinRoom(this.rtcConfig.streamName, this.rtcConfig.streamId);
                            this.signalR.invoke("JoinRoom", this.user.rid, this.user.id, this.user.kid, this.user.name, false, this.user.publisher);

                            if (this.user.publisher) {
                                this.signalR.invoke("PublisherIsLogIn", this.user.rid, this.user.id);
                                this.signalR.invoke("UserCameraToggle", this.user.rid, this.user.id, true);
                                this.signalR.invoke("UserMicrophoneToggle", this.user.rid, this.user.id, true);
                            }
                            
                        }
                    }
                }
            }
        },
        computed: {

        },
        async mounted() {
            try {
                this.antPoputService = new AntPoputService();

                let result = (await AntService.getAuthorization(this.$route.query)).data;
                if (Object.keys(result).length == 0) {
                    return await this.$router.push({ name: "error", params: { code: 401 } });
                }

                if (result.pleyOnly != undefined && result.pleyOnly != null && result.pleyOnly == true) {
                    let joinToMeetingAsQuestResult = await this.$swal(this.antPoputService.confirmJoinToConferenceAsGuest());

                    if (!joinToMeetingAsQuestResult.isConfirmed) {
                        parent.postMessage("vcclose", "*");
                        return await this.$router.push({ name: "error", params: { code: 403 } });
                    }

                    encryptStorage.setItem('ant_tk', result.tk);

                    this.PlayerOnlySettings.PlayOnlyVroom = true;
                    this.PlayerOnlySettings.Rid = result.rid;
                    this.rtcConfig.streamName = `conderence-mwc-${this.PlayerOnlySettings.Rid}`;
                    this.rtcConfig.streamId = `stream-mwc-${this.PlayerOnlySettings.Rid}`;

                    this.participantsList = (await AntService.getCurrentParticipantList(this.PlayerOnlySettings.Rid, 1)).data;
                    this.antStreamManager = new AntStreamManagerService({
                        currentStreamId: this.rtcConfig.streamId
                    });
                    await this.createSignalRConnection();
                    await this.createWebRTCAdaptor();
                }
                else {
                    this.user = result;
                    encryptStorage.setItem('ant_tk', result.tk);
                    this.user.tk = "";

                    this.getUserAvatar();
                    if (this.user.publisher) {
                        this.moduleState.isPublisher = true;
                        let joinToMeetingAsPublisher = await this.$swal(this.antPoputService.confirmJoinToConferenceAsPublisher(this.user.name))

                        if (!joinToMeetingAsPublisher.isConfirmed) {
                            parent.postMessage("vcclose", "*");
                            return await this.$router.push({ name: "error", params: { code: 403 } });
                        }
                    }
                    else {
                        let joinToMeetingAsUser = await this.$swal(this.antPoputService.confirmJoinToConferenceAsUser(this.user.name));

                        if (!joinToMeetingAsUser.isConfirmed) {
                            parent.postMessage("vcclose", "*");
                            return await this.$router.push({ name: "error", params: { code: 403 } });
                        }
                    }

                    this.rtcConfig.streamId = `stream-${this.generateUuidv4()}-mwc-${this.user.id}`; //stream is musi sie konczyć UserId!!!!!!
                    this.rtcConfig.streamName = `conderence-mwc-${this.user.rid}`;
                    if (result.isPin) {
                        // dodatkowa autoryzacja pinem
                        let pinConfirmation = await this.$swal({
                            title: 'Wprowadź PIN',
                            input: 'password',
                            inputAttributes: {
                                autocapitalize: 'off'
                            },
                            confirmButtonText: 'Potwierdź',
                            showLoaderOnConfirm: true,
                            preConfirm: async (login) => {
                                var data = { pin: login };
                                var resPin = (await AntService.checkPinAuth(data)).data;
                                if (Object.keys(result).length > 0) {
                                    if (!resPin.pinAuth) {
                                        this.$swal.showValidationMessage(`Pin nieprawidłowy`);
                                    }
                                }
                                else {
                                    parent.postMessage("vcclose", "*");
                                    return await this.$router.push({ name: "error", params: { code: 403 } });
                                }
                            },
                            allowOutsideClick: false
                        });

                        if (!pinConfirmation.isConfirmed) {
                            var data2 = { uid: this.user.id};
                            await AntService.DeleteToken(data2);
                            parent.postMessage("vcclose", "*");
                            return await this.$router.push({ name: "error", params: { code: 403 } });
                        }
                    }

                    if (this.user.publisher) {
                        this.streamData.isPublish = true;

                        this.moduleState.cameraState = 1;
                        this.moduleState.microphoneState = 1;
                        this.moduleState.liveStream = true;
                    }

                    this.antStreamManager = new AntStreamManagerService({
                        currentStreamId: this.rtcConfig.streamId
                    });

                    this.participantsList = (await AntService.getCurrentParticipantList(this.user.rid)).data;

                    this.peopleCounter.participants = this.participantsList.length;
                    //this.peopleCounter.viewers = (await AntService.getCurrentViewersCount(this.user.rid)).data;
                    await this.createSignalRConnection();
                    this.createeSesjaConnection();
                    await this.createWebRTCAdaptor();
                }

            } catch (e) {
                var data = { error: e.toString() };
                await AntService.saveFrontError(data);

                if (Object.keys(e).length === 0) {
                    return await this.$router.push({ name: "error", params: { code: 500 } });
                }

                if (e.response === undefined) {
                    return await this.$router.push({ name: "error", params: { code: 500 } });
                    
                }

                return await this.$router.push({ name: "error", params: { code: e.response.status } });

            }
        },
        async unmount() {
            try {
                if (this.esesjaWebSocket != null || this.esesjaWebSocket != undefined) {
                    if (this.esesjaWebSocket.connected) {
                        this.esesjaWebSocket.disconnect();
                    }
                }

                if (this.signalR != null || this.signalR != undefined) {
                    this.signalR.invoke("LeaveRoom", this.user.rid, this.user.id);
                }

                if (this.webRTCAdaptor != null || this.webRTCAdaptor != undefined) {

                    for (let stream in this.availableStreams) {
                        this.webRTCAdaptor.stop(stream.streamId);
                    }
                    this.webRTCAdaptor.leaveFromRoom(this.rtcConfig.streamName);

                    this.webRTCAdaptor.stop(this.rtcConfig.streamId);
                    //this.webRTCAdaptor.closePeerConnection(this.rtcConfig.streamName);

                    this.webRTCAdaptor.closeWebSocket();
                }

                var data = { uid: this.user.id };
                await AntService.DeleteToken(data);
            }
            catch (e) {
                console.log(e);
                alert(e);
            }
        },
        beforeUnmount() {
            try {
                if (this.esesjaWebSocket != null && this.esesjaWebSocket != undefined) {
                    if (this.esesjaWebSocket.connected) {
                        this.esesjaWebSocket.disconnect();
                    }
                }

                if (this.signalR != null && this.signalR != undefined && !this.PlayerOnlySettings.PlayOnlyVroom) {
                    this.signalR.invoke("LeaveRoom", this.user.rid, this.user.id);
                }

                if (this.webRTCAdaptor != null && this.webRTCAdaptor != undefined) {

                    for (let stream in this.availableStreams) {
                        this.webRTCAdaptor.stop(stream.streamId);
                    }
                    this.webRTCAdaptor.leaveFromRoom(this.rtcConfig.streamName);

                    this.webRTCAdaptor.stop(this.rtcConfig.streamId);
                    //this.webRTCAdaptor.closePeerConnection(this.rtcConfig.streamName);

                    this.webRTCAdaptor.closeWebSocket();
                }
            }
            catch (e) {
                console.log(e);
                alert(e);
            }
        },
        methods: {
            decryptUkod(ukod) {
                var aes = AesElem.GetAuth();
                var decrypt = CryptoJS.AES.decrypt(ukod, CryptoJS.enc.Utf8.parse(aes.key), { iv: CryptoJS.enc.Utf8.parse(aes.iv) });
                return decrypt.toString(CryptoJS.enc.Utf8);
            },
            generateUuidv4() {
                return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
                    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
                );
            },
            async createWebRTCAdaptor() {
                this.webRTCAdaptor = await new WebRTCAdaptor({
                    websocket_url: this.rtcConfig.websocket_url,
                    mediaConstraints: this.rtcConfig.mediaConstraints,
                    peerconnection_config: this.rtcConfig.peerconnection_config,
                    sdp_constraints: this.rtcConfig.sdp_constraints,
                    localVideoId: this.rtcConfig.videoContainer,
                    isPlayMode: !this.user.publisher,
                    bandwidth: this.rtcConfig.bandwidth, // default is 900 kbps, string can be 'unlimited'
                    debug: this.rtcConfig.debug,
                    //dataChannelEnabled: this.dataChannelEnabled, // enable or disable data channel
                    callback: async (info, obj) => {
                        console.log(info);
                        console.log(obj);
                        if (info == "initialized") {
                            this.externalSystemState.webRTCAdaptorReady = true;
                        }
                        else if (info == "publish_started") {
                            this.streamData.isPublish = true;
                            //SignalR: powiadowienie uczestników pokoju o zmianie nadawania
                            await this.signalR.invoke("UserStreamToggle", this.user.rid, this.user.id, true);

                            if (!this.user.publisher && !this.streamData.isPublish) {
                                this.moduleState.cameraState = 0;
                                await this.signalR.invoke("UserCameraToggle", this.user.rid, this.user.id, false);
                                await this.signalR.invoke("UserMicrophoneToggle", this.user.rid, this.user.id, false);

                            }
                        }
                        else if (info == "publish_finished") {
                            
                            //this.toggleStatictics(); not used in view

                            //SignalR: powiadowienie uczestników pokoju o zmianie nadawania
                            if (this.signalR !== undefined)
                                await this.signalR.invoke("UserStreamToggle", this.user.rid, this.user.id, false);

                            if (!this.user.publisher) {
                                this.streamData.isPublish = false;
                                await this.signalR.invoke("UserCameraToggle", this.user.rid, this.user.id, false);
                                await this.signalR.invoke("UserMicrophoneToggle", this.user.rid, this.user.id, false);
                            }

                        }
                        else if (info == "joinedTheRoom") {
                            if (this.user.publisher) {
                                await this.signalR.invoke("UserStreamToggle", this.user.rid, this.user.id, true);
                                await this.webRTCAdaptor.publish(this.rtcConfig.streamId, "", "", "", this.rtcConfig.streamName);
                            }
                            //po podłączenia do pokoju pobranie listy dostępnych streamów w celu wyświetleniach ich widzowi
                            //this.webRTCAdaptor.getRoomInfo(this.rtcConfig.streamName, this.rtcConfig.streamId);
                        }
                        else if (info == "browser_screen_share_supported") {
                            this.moduleState.isScreenShareSupported = true;
                        }
                        else if (info == "newStreamAvailable") {
                            this.availableStreams = this.antStreamManager.newStreamAvailable(obj);
                            this.streamData.streamsItems = this.availableStreams.length
                            this.webRTCAdaptor.enableAudioLevel(obj.stream, obj.streamId)
                        }
                        else if (info == "updated_stats") {

                            this.videoStatData = obj;
                        }
                        else if (info == "roomInformation") {

                            this.availableStreams = this.antStreamManager.manageStreams(obj.streamList);
                            this.streamData.streamsItems = this.availableStreams.length
                            let streamsToStop = this.antStreamManager.getStreamsToStop();
                            let stramsToPlay = this.antStreamManager.getStreamsToPlay();

                            streamsToStop.forEach(async (obj) => {
                                await this.webRTCAdaptor.stop(obj.streamId);
                            });

                            stramsToPlay.forEach(async (obj) => {
                                await this.webRTCAdaptor.play(obj.streamId, "", this.rtcConfig.streamName);
                            });
                        }
                        else if (info == "streamInformation") {
                            console.log("streamInformation", obj);
                            console.log(obj);
                        }
                        //else if (info == "play_finished") {
                        //    //console.log("play_finished", obj);
                        //}
                        //else if (info == "speaking_but_muted") {
                        //}
                        //else if (info == "session_restored") {
                        //}
                        else if (info == "ice_connection_state_changed") {
                            if (obj.state == "connected" || obj.state == "completed") {
                                //it means the ice connection has been established
                                this.webRTCInitalized = true;
                            }
                        }

                    },
                    callbackError: async (error, message) => {
                        //console.log(error);
                        //console.log(message);
                        let errorMessage = JSON.stringify(error);
                        if (typeof message != "undefined") {
                            errorMessage = message;
                        }
                        errorMessage = JSON.stringify(error);
                        if (error.indexOf("NotFoundError") != -1) {
                            await this.problemWithCameraOrMic();
                        }
                        else if (error.indexOf("NotReadableError") != -1 || error.indexOf("TrackStartError") != -1) {
                            await this.problemWithCameraOrMic();
                        }
                        else if (error.indexOf("OverconstrainedError") != -1 || error.indexOf("ConstraintNotSatisfiedError") != -1) {
                            errorMessage = "There is no device found that fits your video and audio constraints. You may change video and audio constraints"
                        }
                        else if (error.indexOf("NotAllowedError") != -1 || error.indexOf("PermissionDeniedError") != -1) {
                            await this.problemWithCameraOrMic();
                        }
                        else if (error.indexOf("TypeError") != -1) {
                            errorMessage = "Video/Audio is required";
                        }
                        else if (error.indexOf("ScreenSharePermissionDenied") != -1) {
                            errorMessage = "You are not allowed to access screen share";
                        }
                        else if (error.indexOf("WebSocketNotConnected") != -1) {
                            errorMessage = "WebSocket Connection is disconnected.";
                        }
                        else if (error.indexOf("publishTimeoutError") != -1) {
                            await this.sendDiscuss(1)
                            await this.$swal(this.antPoputService.problemWithJoinToConference());
                        }
                        else if (error.indexOf("streamIdInUse") != -1) {
                            //TODO: trzeba się zastnawoić co z tym fantem zrobić
                            //await this.stopPublish();
                            //await this.startPublish();
                        }

                        if (errorMessage !== undefined && errorMessage !== null) {
                            //this.$toast.error(errorMessage);
                        }
                    }, // check error callbacks bellow
                });
            },

            createeSesjaConnection() {
                this.esesjaWebSocket = io(this.esesjaWebSocketConfig.host);

                this.esesjaWebSocket.on("connect", async () => {
                    await this.esesjaWebSocket.emit('viewerconsole', this.user.rid);
                    this.externalSystemState.webSocketReady = true;
                    //await this.createWebRTCAdaptor();
                });

                this.esesjaWebSocket.on("disconnect", () => {
                    console.log("DISCONENCTED: ", this.esesjaWebSocket.id); // undefined
                });

                this.esesjaWebSocket.on("command", async (msg) => {
                    if (msg.includes('api_dlistchanged|')) {
                        let confirmRejectAddToSpeakPoput = null;
                        let rejectAddToSpeeakResult = null;
                        let listChangedData = GlobalParser.ParserApiDListChanged(msg);
                        let requestUserData = this.participantsList.find(el => el.id === listChangedData.uid);
                        if (listChangedData.uid == this.user.id) {
                            switch (listChangedData.state) {
                                case 1:
                                    // zgloszenie do dyskusji z listy gosci
                                    this.$toast.info("Zostałeś zgłoszony do dyskusji", { position: 'top-right' });
                                    this.moduleState.hand = 1;
                                    break;
                                case 2:
                                    // udzielenie głosu z listy dyskutantów
                                    this.moduleState.hand = 0;
                                    this.moduleState.microphoneState = 1;
                                    this.moduleState.cameraState = 1;
                                    this.moduleState.liveStream = true;
                                    await this.startPublish();

                                    break;
                                case 3:
                                    // udzielenie głosu bezpośrednio z listy gości
                                    confirmRejectAddToSpeakPoput = await this.$swal(this.antPoputService.confirmRejectAddToSpeak());

                                    if (confirmRejectAddToSpeakPoput.isConfirmed) {
                                        this.moduleState.hand = 0;
                                        this.moduleState.microphoneState = 1;
                                        this.moduleState.cameraState = 1;
                                        this.moduleState.liveStream = true;
                                        await this.startPublish();
                                    }
                                    else {
                                        rejectAddToSpeeakResult = await this.sendDiscuss(1);

                                        if (rejectAddToSpeeakResult.status === true) {
                                            this.moduleState.hand = 0;
                                            this.moduleState.microphoneState = 0;
                                            this.moduleState.cameraState = 0;
                                            this.moduleState.liveStream = false;
                                            this.$toast.success("Pomyślnie odrzucono prośbę o głos", { position: 'top-right' });
                                        }

                                    }

                                    break;
                                case 4:
                                    // usuniecie z listy dyskutantów
                                    this.moduleState.hand = 0;
                                    this.moduleState.microphoneState = 0;
                                    this.moduleState.cameraState = 0;
                                    this.moduleState.liveStream = false;
                                    this.$toast.info("Zostałeś usunięty z listy dyskutantów", { position: 'top-right' });
                                    break;
                                case 5:
                                    // skończenie wypowiedzi człowiekowi
                                    this.moduleState.hand = 0;
                                    this.moduleState.microphoneState = 0;
                                    this.moduleState.cameraState = 0;
                                    this.moduleState.liveStream = false;
                                    await this.stopPublish();
                                    await this.signalR.invoke("OffFullScreen", this.user.rid);
                                    //TODO: zakończenie powoduje zamknięcie okna ale nie dezaktywuje kamery i mikrofonu (strona https dalej korzysta z mikrofonu i kamery)
                                    //myśle że już ok - wywołanie TurnCameraOff i TurnMicrophoneOff (wyłącza kamerer i mikrfonon)
                                    break;
                                default:
                                    break;
                            }
                        }
                        else {
                            switch (listChangedData.state) {
                                case 1:
                                    if (requestUserData !== undefined && requestUserData !== null) {
                                        this.$toast.info(`<div style="text-align:center"><strong>${requestUserData.name}</strong><br />zgłoszenie do dyskusji</div>`, {
                                            position: 'top'
                                        });
                                    }
                                    break;
                                case 2:
                                    if (!this.user.publisher && this.streamData.isPublish) {
                                        this.moduleState.hand = 0;
                                        this.moduleState.microphoneState = 0;
                                        this.moduleState.cameraState = 0;
                                        this.moduleState.liveStream = false;
                                        await this.stopPublish();
                                    }
                                    break
                                case 4:
                                    if (requestUserData !== undefined && requestUserData !== null) {
                                        this.$toast.info(`<div style="text-align:center"><strong>${requestUserData.name}</strong><br />rezygnacja z dyskusji</div>`, {
                                            position: 'top'
                                        });
                                    }
                                    break;
                            }
                        }
                    }
                })
            },

            async createSignalRConnection() {
                try {
                    this.signalR = new HubConnectionBuilder()
                        .withUrl(this.signalRConfig.hub, { accessTokenFactory: () => encryptStorage.getItem("ant_tk") })
                        .withAutomaticReconnect()
                        .configureLogging(LogLevel.Debug)
                        .build();

                    this.signalR.on("ViewerStreamNotification", async (obj) => {
                        if (this.PlayerOnlySettings.PlayOnlyVroom) {
                            // needed to change streams in vroom viewers - checked (bez tego widok w vroom się nie odświeża)
                            await this.webRTCAdaptor.getRoomInfo(this.rtcConfig.streamName, this.rtcConfig.streamId);
                        }

                        console.log(obj);
                        //else {
                        //    this.peopleCounter.viewers = obj.length;
                        //}
                    });

                    this.signalR.on("ParticipantsListChanged", async (obj) => {
                        this.participantsList = obj;
                        if (!this.PlayerOnlySettings.PlayOnlyVroom) {
                            this.peopleCounter.participants = this.participantsList.length;
                            //przy każdnej zmianie uczestników (np zmiana czy stremuje) pobranie dostępnych streamów w pokoju - bez tego widok u przewodniczącego i reszty radnych się nie odświeża
                            if (this.webRTCAdaptor !== undefined && this.webRTCAdaptor !== null)
                                await this.webRTCAdaptor.getRoomInfo(this.rtcConfig.streamName, this.rtcConfig.streamId);
                        }
                    })

                    this.signalR.on("OnFullScreen", (uid) => {
                        // TODO: dobra z tego co zrozumiałem to jak wywołają fullscreen to przy transmisji 2 osób na raz ma być widoczna tylko ta co jej przesłąli fullscreen
                        this.streamData.isFullscreen = true;
                        this.streamData.fullScreenUserId = uid;
                        //this.$toast.info(`Otrzymano request OnFullScreen ${uid}`, { position: 'top-right' });
                        //console.log(uid);
                    });

                    this.signalR.on("OffFullScreen", (rid) => {

                        this.streamData.isFullscreen = false;
                        this.streamData.fullScreenUserId = 0;
                        //this.$toast.info("Otrzymano request OffFullScreen", { position: 'top-right' });
                        // TODO: z tego co zrozumiałem to ma być po wyłączeniu tego stream przewodniczącego a jak ktoś dostanie głos to oba
                        console.log(rid);
                    });

                    this.signalR.on("IsPublisher", async (isPub, uid) => {
                        if (!this.PlayerOnlySettings.PlayOnlyVroom) {
                            if (isPub) {
                                this.$toast.info("Przewodniczący rozpoczął transmisję", { position: 'top-right' });
                                this.publisherId = uid;
                            } else {
                                this.$toast.info("Przewodniczący opuścił spotkanie", { position: 'top-right' });
                                this.publisherId = 0;
                            }

                            // TODO - sprawdzałem czy potrzebne IMO Nie ale do weryfikacji na produkcji
                            //if (this.webRTCAdaptor !== undefined)
                               // await this.webRTCAdaptor.getRoomInfo(this.rtcConfig.streamName, this.rtcConfig.streamId);
                        }
                    });

                    this.signalR.on("ChangePublisher", async (uid) => {
                        if (!this.PlayerOnlySettings.PlayOnlyVroom) {
                            // zmiana publishera
                            if (this.user.id == uid) {
                                //if (!this.user.publisher) {
                                    this.user.publisher = true;
                                    this.moduleState.hand = 0;
                                    this.moduleState.microphoneState = 1;
                                    this.moduleState.cameraState = 1;
                                    this.moduleState.liveStream = true;
                                    this.moduleState.isPublisher = true;
                                    await this.startPublish();
                                //}
                            } else {
                                //if (this.user.publisher) {
                                    this.user.publisher = false;
                                    this.moduleState.hand = 0;
                                    this.moduleState.microphoneState = 0;
                                    this.moduleState.cameraState = 0;
                                    this.moduleState.liveStream = false;
                                    this.moduleState.isPublisher = false;
                                    await this.stopPublish();
                                //}
                            }
                        }
                    });


                    this.signalR.onreconnecting((error) => {
                        this.$toast.error("Wykryto błąd połączenia", { position: 'top-right' });
                        console.log("this.signalR.onreconnecting", error)
                    });


                    //this.signalR.onreconnected( async (connectionId) => {
                    //    this.$toast.success("Połączenie przywrócone", { position: 'top-right' });
                    //    console.log("Ponownie nawiązano połączenie", connectionId);
                    //    if (this.PlayerOnlySettings.PlayOnlyVroom) {
                    //        await this.signalR.invoke("JoinViewer", this.PlayerOnlySettings.Rid); 
                    //    }
                    //    else {
                    //        await this.signalR.invoke("JoinRoom", this.user.rid, this.user.id, this.user.kid, this.user.name, false, this.user.publisher);
                    //    }
                    //});


                    await this.signalR.start();

                    this.externalSystemState.signalRReady = true;

                }
                catch (e) {
                    console.log("createSignalRConnection", e);
                    var data = { error: e.toString() };
                    await AntService.saveFrontError(data);

                    if (Object.keys(e).length === 0) {
                        return await this.$router.push({ name: "error", params: { code: 500 } });
                    }

                    if (e.response === undefined) {
                        return await this.$router.push({ name: "error", params: { code: 500 } });

                    }

                    return await this.$router.push({ name: "error", params: { code: e.response.status } });
                }
            },

            async turnMicrophoneOff() {
                try {
                    await this.webRTCAdaptor.muteLocalMic();
                    this.moduleState.microphoneState = 0;

                    if (this.signalR === undefined) return;

                    await this.signalR.invoke("UserMicrophoneToggle", this.user.rid, this.user.id, false);
                }
                catch (e) {
                    //console.log("turnMicrophoneOff", e);
                    //this.$toast.error("Wystąpił problem z wyłączeniem mikrofonu", { position: 'top-right' });
                }
            },
            async turnMicrophoneOn() {
                try {
                    if (this.webRTCAdaptor === undefined || this.webRTCAdaptor === null)
                        return;
                    await this.webRTCAdaptor.unmuteLocalMic();
                    this.moduleState.microphoneState = 1;

                    if (this.signalR === undefined) return;

                    await this.signalR.invoke("UserMicrophoneToggle", this.user.rid, this.user.id, true);
                }
                catch (e) {
                    //console.log("turnMicrophoneOn", e);
                    this.$toast.error("Wystąpił problem z włączeniem mikrofonu", { position: 'top-right' });
                }
            },

            async turnCamearaOn() {
                try {
                    if (this.signalR === undefined)
                        return;

                    await this.signalR.invoke("UserCameraToggle", this.user.rid, this.user.id, true);

                    await this.webRTCAdaptor.turnOnLocalCamera(this.rtcConfig.streamId)
                    this.moduleState.cameraState = 1;
                }
                catch (e) {
                    //console.log("turnCamearaOn", e);
                    this.$toast.error("Wystąpił problem z włączeniem kamery", { position: 'top-right' });
                }
            },
            async turnCameraOff() {
                try {
                    if (this.signalR === undefined)
                        return;

                    await this.signalR.invoke("UserCameraToggle", this.user.rid, this.user.id, false);
                    await this.webRTCAdaptor.turnOffLocalCamera(this.rtcConfig.streamId);
                    this.moduleState.cameraState = 0;

                }
                catch (e) {
                    //console.log("turnCameraOff", e);
                    this.$toast.error("Wystąpił problem z wyłączeniem kamery", { position: 'top-right' });
                }
            },
            changeVideoSource(source) {
                if (source === "camera") {
                    this.webRTCAdaptor.switchVideoCameraCapture(this.rtcConfig.streamId);
                }
                else if (source === "screen") {
                    this.webRTCAdaptor.switchDesktopCapture(this.rtcConfig.streamId);
                }
                else if (source === "camera+screen") {
                    this.webRTCAdaptor.switchDesktopCaptureWithCamera(this.rtcConfig.streamId);
                }
            },
            toogleParicipantList() {
                this.showParticipantList = !this.showParticipantList;
            },
            async endCall() {
                if (!this.user.publisher) {
                    await this.sendDiscuss(1); // wysyłka zakończenia swojej wypowiedzi w esesji
                }
                else {
                    await this.signalR.invoke("PublisherLeaveMeeting", this.user.rid, this.user.id); // notyfikacja dla uzytkowników że przewodniczacy opuścił spotkanie
                }

                this.moduleState.hand = 0;
                this.moduleState.microphoneState = 0;
                this.moduleState.cameraState = 0;
                this.moduleState.liveStream = false;

                await this.stopPublish();
            },
            async voiceResignation() {

                if (this.moduleState.liveStream)
                    return;

                let discussResult = await this.sendDiscuss(1);

                if (discussResult.status === false) {
                    await this.$swal(this.antPoputService.problemWithVoiceResignation());
                    return;
                }
                this.moduleState.hand = 0;
            },
            async voiceRequest() {

                if (this.moduleState.liveStream)
                    return;

                let discussResult = await this.sendDiscuss(6);

                if (discussResult.status === false) {
                    await this.$swal(this.antPoputService.problemWithVoiceRequest());
                    return;
                }
                this.moduleState.hand = 1;
            },
            async sendDiscuss(type) {
                let responseObjets = {
                    status: false,
                    message: ""
                }
                try {
                    let data = {
                        dt: 1, // parametr stały zawsze 1
                        typ: type, // 2 - zgłoszenie do dyskusji, 1 - rezygnacja z głosu
                        uid: this.user.id,
                    };

                    if (this.user.jToken == "0") {
                        data.ukod = this.decryptUkod(this.user.ukod);
                    }
                    else {
                        data.token = this.user.token;
                    }

                    let result = await TableteSesjaService.SetDisscus(data);

                    if (result.status !== 200) {
                        responseObjets.status = false;
                        responseObjets.message = `Response status: ${result.status}`;
                    }
                    else {
                        if (result.data === "1" || result.data === "ok") {
                            responseObjets.status = true;
                        }
                        else {
                            responseObjets.status = false;
                            responseObjets.message = `Wystąpił problem z wykonaniem żądania. Spróbuj ponownie`;
                        }
                    }
                }
                catch (ex) {
                    responseObjets.status = false;
                    responseObjets.message = `Wystąpił problem z wykonaniem żądania. Spróbuj ponownie`;
                }
                return responseObjets;

            },
            async startPublish() {
                await this.turnCamearaOn();
                await this.turnMicrophoneOn();
                this.webRTCAdaptor.publish(this.rtcConfig.streamId, "", "", "", this.rtcConfig.streamName);
            },

            async stopPublish() {
                this.moduleState.cameraState = 0;
                this.moduleState.liveStream = false;
                await this.turnCameraOff();
                await this.turnMicrophoneOff();

                this.webRTCAdaptor.stop(this.rtcConfig.streamId);

            },

            async problemWithCameraOrMic() {
                await this.sendDiscuss(1);
                await this.$swal(this.antPoputService.problemWithCameraOrMicrophone())
            },

            // method not used
            toggleStatictics() {
                if (!this.showVideoStatistics) {
                    if (!this.streamData.isPublish) {
                        this.$swal(this.antPoputService.cannotOpenStatistics());
                        return;
                    }
                }
               
                this.showVideoStatistics = !this.showVideoStatistics;

                if (this.showVideoStatistics) {
                    this.webRTCAdaptor.enableStats(this.rtcConfig.streamId);
                }
                else {
                    this.webRTCAdaptor.disableStats(this.rtcConfig.streamId);
                }
            },

             getUserAvatar() {
                this.userAvatar = GlobalParser.GetUserAvatarPath(this.user.id)
            },
        }
    }
</script>
<style type="text/css">
    .mwc-conference-ant {
        background: #000;
        margin-top: 1%;
        min-height: 85vh !important;
        height: 95vh;
        overflow: hidden;
    }

    .mwc-video-item .mwc-video-player .col-12 .ratio {
        width: 75vw !important;
        height: 80vh;
    }
</style>
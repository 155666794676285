export class AntStreamManagerService {

    constructor(params) {
        //list of streams that are current available, streams to display
        this.availableStreams = [];
        //list of streams that have to start play ex. new person start to publish stream
        this.streamsToPlay = [];
        //list of streams that have to stop plat ex. person that actually streaming stop their publish
        this.streamsToStop = [];

        this.currentStreamId = params.currentStreamId;

        console.log("AntStreamManagerService:constructor", this);
    }
    //method that return available streams
    getAvailableStreams() {
        return this.availableStreams;
    }

    getStreamsToPlay() {
        return this.streamsToPlay;
    }

    getStreamsToStop() {
        return this.streamsToStop;
    }
    /**
     * 
     * Method manage current streams if appear
     * 
     * @param newStreamAvailableObj obj
     * 
     * @return void;
     * 
     * */
    newStreamAvailable(newStreamAvailableObj) {

        if (newStreamAvailableObj === undefined || newStreamAvailableObj === null)
            return;

        if (!this.availableStreams.find(e => e.streamId == newStreamAvailableObj.streamId))
            this.availableStreams.push(newStreamAvailableObj);

        return this.getAvailableStreams();
    }

    /**
     * Method that manage available streams that are in ant media server 
     * 
     * @param availableStreamsList Array
     * 
     * @return void
     * 
     * */
    manageStreams(availableStreamsList) {

        if (availableStreamsList === undefined || availableStreamsList === null)
            return;

        if (!Array.isArray(availableStreamsList))
            return;

        this.manageStreamsToStop(availableStreamsList);
        this.manageStreamsToStart(availableStreamsList);
        this.manageAvailableStreams(availableStreamsList);

        return this.getAvailableStreams();
    }

    manageAvailableStreams(availableStreamsList) {
        if (availableStreamsList === undefined || availableStreamsList === null)
            return;

        if (!Array.isArray(availableStreamsList))
            return;

        this.availableStreams = this.availableStreams.filter((obj) => {
            if (obj === undefined)
                return false;

            return availableStreamsList.find((curr) => curr.streamId === obj.streamId) && this.currentStreamId !== obj.streamId;
        });
    }

    manageStreamsToStart(availableStreamsList) {

        if (availableStreamsList === undefined || availableStreamsList === null)
            return;

        if (!Array.isArray(availableStreamsList))
            return;

        this.streamsToPlay = availableStreamsList.filter((obj) => {
            if (obj === undefined)
                return false;
            return !this.availableStreams.find((old) => old.streamId === obj.streamId) && this.currentStreamId !== obj.streamId;
        })
    }

    manageStreamsToStop(availableStreamsList) {

        if (availableStreamsList === undefined || availableStreamsList === null)
            return;

        if (!Array.isArray(availableStreamsList))
            return;

        console.log("manageStreamsToStop", this.availableStreams, availableStreamsList);

        this.streamsToStop = this.availableStreams.filter((obj) => {
            if (obj === undefined)
                return false;

            return !availableStreamsList.find((curr) =>  curr.streamId === obj.streamId )
        })

    }

}
export class AntPoputService {
	confirmJoinToConference() {
        return {
            title: 'Dołaczyć do spotkania?',
            text: 'Czy na pewno chcesz dołączyć do spotkania?',
            confirmButtonText: 'Tak',
            cancelButtonText: 'Nie',
            //icon: 'question',
            showCancelButton: true,
            allowOutsideClick: false
        };
    }

    confirmJoinToConferenceAsGuest() {
        return {
            title: 'Dołaczyć do spotkania?',
            html: 'Czy na pewno chcesz dołączyć do spotkania jako <strong>widz</strong>?',
            confirmButtonText: 'Dołącz',
            cancelButtonText: 'Opuść',
            //icon: 'question',
            showCancelButton: true,
            allowOutsideClick: false
        };
    }

    confirmJoinToConferenceAsPublisher(username) {
        return {
            title: 'Dołaczyć do spotkania?',
            html: `Czy na pewno chcesz dołączyć do spotkania jako <strong>${username}</strong>?`,
            confirmButtonText: 'Dołącz',
            cancelButtonText: 'Opuść',
            //icon: 'question',
            showCancelButton: true,
            allowOutsideClick: false
        };
    }

    confirmJoinToConferenceAsUser(username) {
        return {
            title: 'Dołaczyć do spotkania?',
            html: `Czy na pewno chcesz dołączyć do spotkania jako <strong>${username}</strong>?`,
            confirmButtonText: 'Dołącz',
            cancelButtonText: 'Opuść',
            //icon: 'question',
            showCancelButton: true,
            allowOutsideClick: false
        };
    }

    cannotOpenStatistics() {
        return {
            title: 'Nie można otworzyć statystyk wideo',
            text: "Wystąpił problem z otworzeniem statystyk wideo. Statystyki video są dostępny tylko w trakcie publikacji obrazu",
            //icon: 'error',
            timer: 5000,
            allowOutsideClick: false
        };
    }

    confirmRejectAddToSpeak() {
        return {
            title: 'Dołączyć do dyskusji?',
            text: "Poproszono o zabranie głosu. Aktywować kamerę oraz mikrofon?",
            //icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Tak',
            cancelButtonText: 'Nie',
            allowOutsideClick: false
        }
    }

    problemWithCameraOrMicrophone() {
        return {
            title: 'Problem z uruchomienie kamery lub mikrogonu',
            text: "Wystąpił problem podczas uruchamiania kamery lub mikrofonu. Sprawdź uprawnienia dostępu do kamery lub mikrofonu",
            //icon: 'error',
            timer: 5000,
        }
    }

    problemWithVoiceResignation() {
        return {
            title: 'Problem z rezygnącja z głosu',
            text: "Wystąpił problem podczas rezygnacji z chęci zabrania głosu. Spróbuj ponownie",
            //icon: 'error',
            timer: 5000,
        }
    }

    problemWithVoiceRequest() {
        return {
            title: 'Problem z dołączeniem do listy mówców',
            text: "Wystąpił problem podczas dołączania do listy mówców. Spróbuj ponownie",
            //icon: 'error',
            timer: 5000,
        }
    }

    problemWithJoinToConference() {
        return {
            title: 'Problem z dołączeniem do konferencji',
            text: "Wystąpił problem podczas dołączania konferencji. Spróbuj zgłosić się ponownie",
            icon: 'error',
            timer: 5000,
        }
    }

}
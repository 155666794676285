import ApiService from "./api.service";

class TableteSesjaService extends ApiService {

    SetDisscus(data) {
        //return this.post(this.GetEndpoint(), data, this.GetHeaders());
        return this.post(
            "https://tablet.esesja.pl/moduly/dyskutuj.asp",
            data,
            this.GetConfig("https://tablet.esesja.pl/moduly/", "dyskutuj.asp", "post", "text/html"));
    }

    GetConfig(_baseUrl, _action, _method, _responseType) {
        return {
            url: _action,
            method: _method,
            baseURL: _baseUrl,
            withCredentials: false,
            responseType: _responseType,
            responseEncoding: "utf-8",
            headers: {
                Accept: _responseType,
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            }
        }
    }

    GetCurrentList(uid, ukod) {

        let endpoint = "https://tablet.esesja.pl/moduly/pobierz_dyskutantow.asp?uid=" + uid + "&ukod=" + ukod;

        return this.get(
            endpoint,
            this.GetConfig("https://tablet.esesja.pl/moduly/", "pobierz_dyskutantow.asp", "get", "application/json")
        );
    }
}

export default new TableteSesjaService();
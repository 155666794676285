<template>

    <CommonErrorComponent :code="currentError.code"
                          :title="currentError.title"
                          :meessage="currentError.message" />
</template>
<script charset="utf-8">
    import CommonErrorComponent from "@/components/common/CommonErrorComponent";
    export default {
        components: {
            CommonErrorComponent
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                errorDict: {
                    400: {
                        code: 400,
                        title: "Błędne żądanie",
                        message: "Nie mogliśmy przetworzyć Twojego żądania"
                    },
                    401: {
                        code: 401,
                        title: "Brak autoryzacji",
                        message: "Nie mogliśmy potwierdzić Twojej tożsamości"
                    },
                    403: {
                        code: 403,
                        title: "Brak dostępu",
                        message: "Nie masz dostępu do tej częsci witryny"
                    },
                    404: {
                        code: 404,
                        title: "Nie znaleziono",
                        message: "Strona której szukasz nie została odnaleziona"
                    },
                    500: {
                        code: 500,
                        title: "Wewnętrzny błąd",
                        message: "Napotkano wewnętrzny błąd serwera"
                    },
                    999: {
                        code: 500,
                        title: "Nieznany błąd",
                        message: "Napotkano nieznany błąd"
                    }
                },

                currentError: {
                    code: 500,
                    title: "Nieznany błąd",
                    message: "Napotkano nieznany błąd"
                }
            };
        },
        computed: {
            getErrorCode() {
                return parseInt(this.$route.params.code)
            }
        },
        mounted() {
            if (this.getErrorCode in this.errorDict) {
                this.currentError = this.errorDict[this.getErrorCode];
            }


        },
        methods: {


        }
    }
</script>
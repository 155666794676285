class AesElem {
    GetAuth() {
        let auth = {
            key: "log87s56d7d82ja0",
            iv: "m9skir04u4y5thd7",
        };
        return auth;
    }
}

export default new AesElem();
<template>
    <div class="esejsa-watermark">
        <a href="https://esesja.pl">
            <div class="watermark"></div>
        </a>
    </div>
</template>
<script charset="utf-8">
    export default {
        data() {
            return {
                publicPath: process.env.BASE_URL,
            };
        },
        computed: {},
        methods: {},
    }

</script>
<style type="text/css">
    .esejsa-watermark {
        position: fixed;
        top: 10%;
        left: 0;
        z-index: 999999;
    }
        .esejsa-watermark .watermark {
            background: url(https://tablet.esesja.pl/images/esesja_videokonferencja.png) right no-repeat;
            background-size: contain;
            width:168px;
            height: 50px;
            filter: contrast(75%);
        }

    @media screen and (max-width: 450px) {
        .esejsa-watermark {
            display: none !important;
        }
    }

</style>
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import Toaster from '@meforma/vue-toaster';

/* SweetAlert */

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router';


let app = createApp(App)
app.use(router)
app.use(VueSweetalert2);
app.use(Toaster, {
    // One of the options
    position: 'bottom-right'
})
app.mount('#app')

<template>
    <div class="row user-avatar mb-3">
        <div class="col-6 offset-3 col-sm-12 offset-sm-0 d-flex justify-content-center">
            <img :src="userAvatar" :alt="userName" class="rounded-circle shadow-4-strong img-fluid" />
        </div>
    </div>
</template>
<script charset="utf-8">
    export default {
        props: {
            userAvatar: {
                type: String,
                required: true,
            },
            userName: {
                type: String,
                required: true,
            }
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
            };
        },
        computed: {},
        methods: {},
    }
</script>
<style type="text/css">

</style>